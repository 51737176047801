define("ntfrontend/services/endpoint", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    host: Ember.computed(function () {
      var host = EmberENV.adapterHost;

      if (Ember.isNone(host)) {
        var ssl = this.get('urlChecker.isStaging') ? false : true;
        return this.urlChecker.subdomainUrl('api', ssl);
      } else {
        return host;
      }
    }),
    namespace: Ember.computed(function () {
      return EmberENV.adapterAPI;
    }),
    baseAPI: Ember.computed('host', 'namespace', function () {
      return "".concat(this.host, "/").concat(this.namespace);
    }),
    buildURL: function buildURL(path) {
      return "".concat(this.baseAPI, "/").concat(path);
    },
    // endpoints
    logout: Ember.computed('baseAPI', function () {
      return this.buildURL('auth/sign_out');
    }),
    login: Ember.computed('baseAPI', function () {
      return this.buildURL('auth/sign_in');
    }),
    register: Ember.computed('baseAPI', function () {
      return this.buildURL('auth');
    }),
    resetPassword: Ember.computed('baseAPI', function () {
      return this.buildURL('auth/password');
    }),
    confirmation: Ember.computed('baseAPI', function () {
      return this.buildURL('auth/confirmation');
    }),
    uploadPreSign: Ember.computed('baseAPI', function () {
      return this.buildURL('upload-presign');
    }),
    magicLink: Ember.computed('baseAPI', function () {
      return this.buildURL('auth/magic-link');
    })
  });

  _exports.default = _default;
});